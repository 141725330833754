// mobile.component.jsx

import React from 'react'
import './mobile.css'
export const Mobile = () => {
    return (
        <div className="mobile">
            {/* <p>Whoops! I'm in mobile mode.</p> */}
        </div>
    )
}