// desktop.component.jsx

import React from 'react'
import './desktop.css'
export const Desktop = () => {
    return (
        <div className="desktop">
            <p>Whoops! I'm in desktop mode.</p>
            <p>But if you see anything below me, i am now in Laptop mode</p>
        </div>
    )
}