import React from 'react'
import './big-screen.css'
export const BigScreen = () => {
    return (
        <div className="big-screen">
            <p>Whoops! I'm in big screen mode.</p>
            <p>This is the base of the pyramid</p>
        </div>
    )
}
